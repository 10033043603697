<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li *ngIf="detailUser?.length<=0"><a routerLink="/">Home</a></li>
                <li *ngIf="detailUser"><a routerLink="/elenco-corsi/i-miei-corsi">Home</a></li>
                <li>Contatti</li>
            </ul>
            <h2>Contattaci</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-image">
    <img  src="assets/img/university-contatti.jpg"  style="max-height:550px; width:100%; object-fit: cover;  object-position: bottom bottom;" alt="image">
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row mb-5">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Chi siamo</span>
                    <h2>Startcode Srl</h2>
                    <p>
                        Startcode Srl è una società costituita da specialisti in finanza aziendale e formazione professionale, da esperti in politiche attive del lavoro e consulenza aziendale.
                    </p>
                    <p>
                        Offre servizi di consulenza e formazione alle aziende, alle agenzie per il lavoro e ai professionisti che hanno bisogno di progetti di formazione, anche su materie specialistiche, definendone i contenuti al richiedente.
                    </p>
                    <p>
                        E’ in grado di reperire sul mercato gli specialisti più affidabili e competenti in modo da trasferire know-how aggiornati e competitivi. L’ esperienza, l’ indipendenza, la capacità di adattamento e di innovazione che ci contraddistinguono insieme alla rete di collaboratori costruita in oltre 15 anni di attività ci consentono di garantire un livello di progettazione e di realizzazione dei piani formativi di alta qualità in tutta Italia.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">dalla formazione alla professione</span>
                    <h2>La nostra mission</h2>
                   <p>
                        Ogni attività di Startcode Srl è volta ad agevolare l’incontro delle persone con il lavoro. Per questo supportiamo i percorsi di carriera delle persone cercando di individuare le linee formative più rispondenti alle loro potenzialità facendo leva su tutte le possibili opportunità e sinergie presenti sul territorio e a livello nazionale, a partire dalla formazione finanziata completamente gratuita di cui possono usufruire
                    </p>
                    <p>
                        La nostra missione quotidiana è sostenere le persone a realizzarsi professionalmente e, allo stesso tempo, offrire ai nostri clienti servizi che si contraddistinguono per qualità e competenza. Il motto che ispira ogni nostra azione è “mettere la persona giusta al posto giusto”. Le nostre attività coinvolgono così tante persone ogni giorno che è per noi motivo di orgoglio e forte responsabilità fare la differenza nelle loro vite e nei percorsi di ricollocamento o riqualificazione.
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Informazioni</span>
                    <h2>Mettiti in contatto</h2>
                    <p>Ci piace ascoltare i lettori. Apprezziamo davvero che tu abbia dedicato del tempo per metterci in contatto.</p>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Dove siamo</h3>
                            <p>Via Salvatore De Renzi, 62<br>83100 Avellino (AV)</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contatti</h3>
                            <p>Telefono: <a href="tel:+3908251994773">(+39) 0825-1994773</a></p>
                            <p>e-mail: <a href="mailto:info@startcodesrl.it">info@startcodesrl.it</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Siamo disponibili</h3>
                            <p>Lunedì - Venerdì<br>09:00 - 18:00</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>Come possiamo aiutarti?</h2>
                    <p>L'indirizzo email non verrà pubblicato. i campi richiesti sono contrassegnati dal simbolo*</p>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Nome e Cognome">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Nome e Cognome richiesto.</div>
                                        <div *ngIf="name.errors.minlength">Numero minimo {{ name.errors.minlength.requiredLength }} di caratteri.</div>
                                        <div *ngIf="name.errors.maxlength">Numero massimo di 50 caratteri.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="la tua e-mail">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="cellulare">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">un contatto telefonico è obbligatorio</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="scrivi il tuo messaggio..."></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">il messaggio è obbligatorio</div>
                                </div>
                            </div>
                            <h2 class="mt-3">Quale corso sei interessato?</h2>
                            <p>seleziona il corso interessato alla quale vuoi avere informazioni*</p>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <select name="sceltaCorso" ngModel id="sceltaCorso" #sceltaCorso="ngModel" style="display: block;
                                    width: 100%;
                                    border: none;
                                    color: var(--blackColor);
                                    background-color: #F3F6F9;
                                    height: 50px;
                                    padding-left: 15px;
                                    border-radius: 5px;
                                    font-size: var(--fontSize);
                                    font-weight: 600">
                                        <option value="{{elencoCorsiRow['corso']['titolo']}}" *ngFor="let elencoCorsiRow of elencoCorsi">{{elencoCorsiRow['corso']['titolo']}}</option>
                                      </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 mt-3">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Invia Richiesta<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
